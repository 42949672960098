<template>
  <section
    class="message-list-layout"
    :style="{
      padding: $arMediaQuery.pageContent.maxWidth('sm') ? '30px 18px' : '47px 59px',
    }"
  >
    <am2-message-list-settings-modal
      v-if="currentMessageList"
      title="List Settings"
      :is-show="showMessageListSettingsModal"
      :message-list="currentMessageList"
      @close="showMessageListSettingsModal = false"
      @update="handleMessageListSettingsConfirm"
    />
    <am2-step-link
      text="Back to Lists"
      @click="handleBackLinkClick"
    />
    <ImportProgressBarModal
      v-if="isShowImportProgressBar"
      :progress="importProgressData.progress"
      :total-rows="importProgressData.totalRows"
      :rows-added="importProgressData.rowsAdded"

    />
    <div
      :class="[
        'message-list-top-wrapper',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      ]">
      <div :class="[
        'message-list-top-left-section',
        $arMediaQuery.pageContent.minWidth('sm') && 'sm-min',
      ]">
        <div
          v-show="!showMessageListNameEdit"
          class="title-section"
        >
          <am2-heading
            class="title"
            type="h1"
            size="lg"
            :title="messageListName"
            @click.native="handleEditNameInputOpen"
          />
          <ar-icon
            class="icon u-margin-left-10"
            name="edit"
            @click="handleEditNameInputOpen"
          />
          <am2-tag
            v-if="isAutoMessageList"
            class="tag"
            text="Dynamic"
            type="purple"
            v-tooltip.top="{
              content: 'Existing and future contacts that match your segment will automatically be added to this list',
            }"
          />
        </div>
        <div
          v-show="showMessageListNameEdit"
          class="edit-section"
        >
          <div class="edit-section-input">
            <input
              ref="edit-name-input"
              v-model="messageListNameEdit"
              data-vv-name="messageListNameEdit"
              class="edit-name-input"
              input-font-size="lg"
              @blur="handleEditNameInputBlur"
              @keyup.esc="handleEditNameInputBlur"
              @keyup.enter="handleEditNameInputEnter"
            />
            <ar-text
              size="sm"
              class="edit-name-input-done"
              text="<a>Done</a>"
              allow-html
              @anchorMouseDown="handleEditNameInputEnter"
            />
          </div>
        </div>
      </div>
      <div class="message-list-top-right-section">
        <am2-split-dropdown
          v-if="showNewMessageButton"
          class="u-margin-left-2"
          :items="newMessageButtonOptions"
          text="New Message"
          icon-name="dialog"
          icon-distance="12px"
          toggle-dropdown-on-button-click
          @select="item => item.action()"
          @buttonClick="() => {null}"
          :style="{
            minWidth: '195px',
          }"
        />
        <am2-expand-icon-button
          v-if="currentMessageList"
          class="u-margin-left-2"
          icon-name="settings"
          @click="handleSettingsClick"
        />
      </div>
    </div>
    <ar-snackbar
      v-if="noFacebookIntegration"
      type="error"
      message="Integration error - <a>please reconnect your Facebook account</a>"
      :style="{ marginTop: '10px' }"
      @anchorClick="handleIntegrationClick"
    />
    <ar-divider
      :style="{
        marginTop: '22px',
        marginBottom: hasPendingTasks ? '10px' : '22px',
      }"
    />
    <ar-snackbar
      v-if="hasPendingTasks"
      type="warning"
      :message="pendingTasksCopy"
    />
    <div v-if="isFetchingMessageList" class="loading-section">
      <am2-loading-bubble />
    </div>
    <nuxt-child
      v-else-if="currentMessageList"
      @contactsCountUpdate="handleContactsCountUpdate"
    />
  </section>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { mergeObjects, arraysEqualUnsorted } from '@/utils/helpers/';
import ImportProgressBarModal from '@/components/modals/message-sender/ImportProgressBarModal.vue';

export default {
  name: 'MessageListView',
  layout: 'default',

  components: {
    ImportProgressBarModal
  },
  data: () => ({
    showMessageListSettingsModal: false,
    showMessageListNameEdit: false,
    messageListNameEdit: null,
    messageListFacebookPages: [], // Only populated if list contains FB Messenger
    contactsCount: 0,
    pendingTasksInterval: null,
    importStatusInterval: null,
    importProgressData: {
      oid: null,
      progress: 0,
      rowsAdded: 0,
      totalRows: 0,
    },
  }),

  computed: {
    ...mapState({
      oid: state => parseInt(state.route.params.oid, 10),
      path: state => state.route.path,
      currentMessageList: state => state.messageList.currentMessageList,
      isFetchingMessageList: state => state.messageList.isFetchingMessageList,
      isFetchingSubscribers: state => state.subscriber.isFetchingSubscribers,
      promoterOid: state => state.auth.account?.promoterOid,
    }),
    ...mapGetters({
      pendingTasksForList: 'promoterTasks/pendingTasksForList',
      pendingExportTasksForMessageList: 'promoterTasks/pendingExportTasksForMessageList',
      pendingMassEditTasksForMessageList: 'promoterTasks/pendingMassEditTasksForMessageList',
      pendingCsvImportTasksForMessageList: 'promoterTasks/pendingCsvImportTasksForMessageList',
    }),

    isImportingContacts() {
      return (this.pendingMassEditTasksForMessageList(this.oid).length > 0 ||
          this.pendingCsvImportTasksForMessageList(this.oid).length > 0) &&
        this.pendingExportTasksForMessageList(this.oid).length === 0;
    },

    isShowImportProgressBar() {
      return this.importProgressData?.oid;
    },

    pendingTasksCopy() {
      if (!(this.pendingMassEditTasksForMessageList(this.oid).length > 0 ||
        this.pendingCsvImportTasksForMessageList(this.oid).length > 0) &&
        this.pendingExportTasksForMessageList(this.oid).length > 0) {
        return "Export in progress. You'll receive an email soon."

      } else if ((this.pendingMassEditTasksForMessageList(this.oid).length > 0 ||
        this.pendingCsvImportTasksForMessageList(this.oid).length > 0) &&
        this.pendingExportTasksForMessageList(this.oid).length > 0) {
        return "Multiple tasks in progress (Import and Export).";
      }
      return ""
    },

    hasPendingTasks() {
      return this.pendingTasksCopy && this.pendingTasksForList(this.oid).length > 0;
    },

    messageListName() {
      if (!this.currentMessageList) return '';
      if (this.currentMessageList.meta.name) return this.currentMessageList.meta.name;
      return this.currentMessageList.name
    },

    isAutoMessageList() {
      if (!this.currentMessageList) {
        return false;
      }
      return this.currentMessageList.filterGroupOid ? true : false;
    },
    noFacebookIntegration() {
      if (!this.currentMessageList || !this.currentMessageList.facebookMessenger) return false; // Just return false if there's no FB messenger on this list
      if (!this.currentMessageList.meta || !this.currentMessageList.meta.facebookMessenger || !this.currentMessageList.meta.facebookMessenger.pageId) return false; // If the list is missing a Facebook Page ID, return false
      if (!this.messageListFacebookPages || this.messageListFacebookPages.length < 1) return false; // If there's no pages, return false (otherwise we flash the error while the pages are loaded)
      const targetPage = this.messageListFacebookPages.find( item => item.puid === this.currentMessageList.meta.facebookMessenger.pageId);
      if (!targetPage) return true; // If the list's page can't be found, return true
      return targetPage.authorized === false; // If the target page is not authorized, return true;
    },

    newMessageButtonOptions() {
      const options = [];

      if (!this.currentMessageList) return [];

      if (process.env.arEnableEmailSending && this.currentMessageList.email) {
        options.push({
          name: 'Email',
          icon: {
            name: 'email',
            color: this.$arStyle.color.email,
            height: '14px',
            width: '19px',
            wrapperStyle: {
              width: '19px',
            }
          },
          action: this.handleNewEmailMessageClick,
        });
      }

      if (this.currentMessageList.sms) {
        options.push({
          name: 'SMS',
          icon: {
            name: 'sms',
            color: this.$arStyle.color.sms,
            height: '19px',
            width: '18px',
            wrapperStyle: {
              width: '19px',
            }
          },
          action: this.handleNewSmsMessageClick,
        });
      }
      return options;
    },

    showNewMessageButton() {
      if (this.contactsCount === 0) return false;
      if (!this.currentMessageList) return false;
      if (!this.currentMessageList.sms && !this.currentMessageList.email) return false

      return true;
    }
  },

  created() {
    this['messageList/RESET_CURRENT_MESSAGE_LIST']();
  },

  async mounted() {
    await this.fetchMessageList();
    if (this.currentMessageList) {
      this.messageListNameEdit = this.currentMessageList.name;
    }
    if (this.currentMessageList && !!this.currentMessageList.facebookMessenger) {
      this.fetchFacebookIntegrations();
    }

    this['promoterTasks/START_POLLING_PENDING_TASKS']({
      reload: true,
    });

    this.checkPendingTasks();
  },

  beforeDestroy() {
    this['messageList/RESET_CURRENT_MESSAGE_LIST']();
  },

  watch: {
    isImportingContacts: {
      handler: function(val) {
        if (val) {
          this.startImportStatusPolling();
        }
      },
      immediate: true,
    }
  },

  methods: {
    ...mapActions([
      'messageList/FETCH_CURRENT_MESSAGE_LIST',
      'messageList/UPDATE_MESSAGE_LIST',
      'promoterTasks/START_POLLING_PENDING_TASKS',
      'FETCH_FACEBOOK_PAGES',
      'SHOW_CONFIRM',
      'messageList/FETCH_MESSAGE_LIST_PROGRESS_BY_OID'
    ]),
    ...mapMutations([
      'messageList/RESET_CURRENT_MESSAGE_LIST',
    ]),

    async fetchMessageListImportStatus() {
      if (!this.promoterOid || !this.oid) return;

      const data = await this['messageList/FETCH_MESSAGE_LIST_PROGRESS_BY_OID']({
        messageListOid: this.oid
      });

      // If data is empty, stop polling as import is complete
      if (!data || data.length === 0) {
        this.stopImportStatusPolling();
        this.importProgressData.oid = null;
        this.fetchMessageList()
        return;
      }

      const result = data[0];
      this.importProgressData = {
        oid: result?.oid || null,
        progress: result?.statusDetails?.progress || 0,
        rowsAdded: result?.statusDetails?.rowsProcessed || 0,
        totalRows: result?.statusDetails?.totalRows || 0,
      };
    },

    startImportStatusPolling() {
      this.stopImportStatusPolling();

      this.fetchMessageListImportStatus();

      this.importStatusInterval = setInterval(() => {
        this.fetchMessageListImportStatus();
      }, 10000);
    },

    stopImportStatusPolling() {
      if (this.importStatusInterval) {
        clearInterval(this.importStatusInterval);
        this.importStatusInterval = null;
      }
    },

    async fetchFacebookIntegrations() {
      const pages = await this.FETCH_FACEBOOK_PAGES();
      if (pages) {
        this.messageListFacebookPages = pages;
      }
    },

    getEditNameInputElem() {
      return this.$refs['edit-name-input'];
    },

    fetchMessageList() {
      this['messageList/FETCH_CURRENT_MESSAGE_LIST']({oid: this.oid});
    },
    handleContactsCountUpdate(val) {
      this.contactsCount = val;
    },
    handleSettingsClick() {
      this.showMessageListSettingsModal = true;
    },
    handleMessageListSettingsConfirm() {
      this.showMessageListSettingsModal = false;
    },

    handleBackLinkClick() {
      this.$router.push({
        path: '/message-center/lists',
      });
    },

    handleEditNameInputOpen() {
      this.showMessageListNameEdit = true;
      this.messageListNameEdit = this.messageListName;
      this.$nextTick(() => {
        this.getEditNameInputElem().focus();
      });
    },

    handleEditNameInputBlur() {
      this.showMessageListNameEdit = false;
    },

    handleIntegrationClick() {
      this.$router.push({
        path: `/settings/integrations`
      });
    },

    async handleEditNameInputEnter() {
      if (!this.messageListNameEdit) {
        this.showMessageListNameEdit = false;
        this.messageListNameEdit = this.messageListName;
        return;
      }
      const succeed = await this['messageList/UPDATE_MESSAGE_LIST']({
        oid: this.oid,
        changes: {
          name: this.messageListNameEdit,
        },
      });
      if (succeed) {
        this.showMessageListNameEdit = false;
      }
    },

    handleNewEmailMessageClick() {
      this.createNewEmail();
    },

    handleNewSmsMessageClick() {
      this.createNewMessage('sms');
    },

    async createNewEmail() {
      if (this.$arMediaQuery.window.maxWidth('xs')) {
        const response = await this.SHOW_CONFIRM({
          messageHtml: `You are able to create and edit emails using your desktop or tablet device.`,
          hideConfirmButton: true,
          cancelButtonText: 'Back',
        });
      } else {
        this.$router.push({
          path: `/message-center/messages/email/new/basic`,
          query: {
            messageList: this.oid
          },
        });
      }
    },

    createNewMessage(type) {
      if (type === 'sms') {
        this.$router.push({
          path: `/message-center/messages/sms/new`,
          query: {
            messageList: this.oid
          },
        });
      }
    },

    checkPendingTasks() {
      this['promoterTasks/START_POLLING_PENDING_TASKS']({
        reload: true,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.message-list-layout {
  .loading-section {
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    height: 200px;
  }


  .message-list-top-wrapper {
    margin-top: 27px;
    display: flex;
    flex-direction:row;
    justify-content: space-between;

    &.xs-max {
      flex-direction:column;
    }

    .message-list-top-left-section {
      display: flex;
      flex-direction:row;
      min-width: 0; // default min-width is auto, we have to replace it with 0
      align-items: center;
      width: 100%;
      margin-bottom: 12px;
      flex-grow: 1;

      &.sm-min {
        margin-bottom: 0;
      }
    }

    .message-list-top-right-section {
      display: flex;
      flex-shrink: 0;
    }
  }

  .title-section {
    display: flex;
    align-items: center;
    height: 40px;
    flex-direction: row;
    max-width: calc(100% - 30px);

    .title {
      cursor: pointer;
    }
    .icon {
      cursor: pointer;
      color: $blueGrey500;
      height: 16px;
    }
    .tag {
      height: 30px;
      margin-left: 19px;
      padding: 0 24px;
    }
  }

  .edit-section {
    display: flex;
    flex-direction: column;
    width: 100%;

    .edit-section-input {
      display: flex;
      flex-direction: row;
      width: 100%;
      .edit-name-input {
        width: calc(100% - 35px);
        height: 40px;
        border: none;
        box-shadow: none;
        background: none;
        background-color: none;
        font-size: 30px;
        color: $skyBlueGrey800;
        border-bottom: 1px dashed $purple200;
        padding-bottom: 4px;
        outline: none;
      }
      .edit-name-input-done {
        color: $purple500;
        text-decoration: underline;
        cursor:pointer;
        margin: 0 0 0 12px;
        align-self:center;
      }
      .input-confirm {
        width: 35px;
        display:inline-flex;
        margin-left: 8px;
      }
    }
  }
}
</style>
